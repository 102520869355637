import React from 'react'
import tw from 'twin.macro'

export default function CenteredText() {
  return (
    <section
      className="centeredText mm-px-dynamic mm-py-dynamic"
      css={tw`flex justify-center text-center`}
    >
      <h4>
        STM TINIUM sætter ingen
        <br /> begrænsninger for dine drømme <br />
        og din kreativitet.
      </h4>
    </section>
  )
}
